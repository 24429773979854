<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <el-form :model="form" :rules="rules" ref="form" width="500px" label-width="200px">
      <el-form-item prop="client_app_code" label="app编号" >
        <el-select v-model="form.client_app_code" @change="appSelect">
          <el-option
            v-for="item in applist"
            :key="item.code"
            :label="item.name"
            :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="package_name" label="APP应用包名" >
        <el-input :disabled="!edit" v-model="form.package_name" type="text" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="apns_host" label="推送服务器ssl地址" >
        <el-input v-model="form.apns_host" type="text" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="pwd" label="推送服务器密码" >
        <el-input v-model="form.pwd" type="text" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="cert" label="推送证书地址" >
        <el-input v-model="form.cert" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="title" label="推送消息title" >
        <el-input v-model="form.title" type="text" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="msg" label="Alert显示消息内容" >
        <el-input v-model="form.msg" type="text" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
        <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      edit: false,
      form: {
        id: '',
        client_app_code: '',
        apns_host: '',
        pwd: '',
        cert: '',
        title: '',
        msg: '',
        package_name: '',
      },
      rules: {
        client_app_code: [{ required: true, message: '请输入app编号', trigger: 'blur' }],
        apns_host: [{ required: true, message: '请输入推送服务器ssl地址', trigger: 'blur' }],
        pwd: [{ required: true, message: '请输入推送服务器密码', trigger: 'blur' }],
        cert: [{ required: true, message: '请输入推送证书地址', trigger: 'blur' }],
        title: [{ required: true, message: '请输入推送消息title', trigger: 'blur' }],
        msg: [{ required: true, message: '请输入推送消息Alert显示消息内容', trigger: 'blur' }],
        package_name: [{ required: true, message: '请输入APP应用包名', trigger: 'blur' }],
      },
      applist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    appSelect () {
      if (!this.edit) {
        this.applist.forEach(item => {
          if (item.code == this.form.client_app_code) {
            this.form.package_name = item.ios_package
          }
        })
      }
    },
    Getinfo () {
      this.api.ApnsDetail({id:this.$route.params.id}).then(res => {
        if (res.data.code == 200) {
          this.form=res.data.data
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    GetappCode() {
        this.api.ClientList({
            page: 1,
            limit: 100
        }).then(res => {
        if (res.data.code == 200) {
          this.applist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    update () {
      this.api.ApnsUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title +'成功')
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    addconfirm (){
        this.update()
    },
    editconfirm (){
        this.update()
    },
    goback () {
      this.$router.push('/iot/app/apns')
    }
  },
  filters: {},
  mounted () {
    if (this.$route.params.id !== '0') {
      this.Getinfo()
      this.edit = true
    } else {
      this.edit = false
    }
  },
  created () {
    this.GetappCode()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.el-form {
    width:600px;
}
</style>
